import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { JobVisitsSchedulerUtils } from 'common/Scheduler'
import { Spinner } from 'common'
import { StyledWrapper } from '../../common/Scheduler/styledComponents'
import { PlusIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { VisitForm } from 'components/Visits'

export function Scheduler() {
	const [openEventForm, setOpenEventForm] = useState(false)
	const {
		visitsLoading,
		headerToolbar,
		eventContent,
		eventDrop,
		formatEvents,
	} = JobVisitsSchedulerUtils()

	if (visitsLoading) {
		return <Spinner />
	}
	return (
		<StyledWrapper>
			<div className="flex justify-end mb-6">
				<button
					type="button"
					className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
					onClick={() => setOpenEventForm(true)}>
					<PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					Create Event
				</button>
			</div>
			<FullCalendar
				headerToolbar={{
					...headerToolbar,
					center: 'dayGridMonth',
				}}
				views={{
					Month: {
						type: 'dayGridMonth',
						slotLabelInterval: { days: 1 },
						weekends: true,
						eventContent,
					},
				}}
				eventContent={eventContent}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin,
					resourceTimelinePlugin,
				]}
				initialView="dayGridMonth"
				height="auto"
				editable
				selectable
				dayMaxEvents
				firstDay={1}
				events={formatEvents()}
				eventDrop={eventDrop}
				schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
			/>
			<VisitForm
				heading="Create Event"
				setOpen={setOpenEventForm}
				formType="create"
				open={openEventForm}
				isEvent
			/>
		</StyledWrapper>
	)
}
