import React, { useEffect, useState } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel'
import classNames from 'classnames'

interface CheckboxOption {
	title: string
	text: string
	type: string
	checked: boolean
}

interface RequirementsConditionsProps {
	id: string
	sharedCheckBoxes: CheckboxOption[]
	labelInline?: boolean
	title: string
	error?: string
}

export const RequirementsConditions: React.FC<RequirementsConditionsProps> = ({
	id,
	sharedCheckBoxes,
	labelInline = false,
	title,
	error,
}) => {
	const [additionalConditions, setAdditionalConditions] = useState<
		CheckboxOption[]
	>([])

	useEffect(() => {
		if (sharedCheckBoxes) {
			setAdditionalConditions(sharedCheckBoxes)
		}
	}, [sharedCheckBoxes])

	const generalRequirements = additionalConditions.filter(
		(element) => element.type === 'General Requirement' && element.checked
	)
	const additionalConditionsList = additionalConditions.filter(
		(element) => element.type === 'Additional Condition' && element.checked
	)

	return (
		<div
			className={classNames(
				labelInline
					? 'space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5'
					: 'block',
				'w-full px-4 py-4 relative z-0'
			)}>
			<ScrollPanel
				style={{ width: '100%' }}
				className="additional-condition-bar">
				<div></div>
				<div className="sm:col-span-2">
					<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
						General Scaffolding Requirements
					</h2>
					{generalRequirements.length > 0 ? (
						generalRequirements.map((line, index) => (
							<div key={index} className="sm:col-span-2">
								<div className="sm:col-span-2">
									<p className="mt-2 text-sm">
										<b>{line.title}</b>
									</p>
								</div>
								<div>
									<p className="mt-2 text-sm">{line.text}</p>
								</div>
							</div>
						))
					) : (
						<p className="mt-2 text-sm">
							<b>NONE</b>
						</p>
					)}
					<br />
					<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
						Additional Conditions
					</h2>
					{additionalConditionsList.length > 0 ? (
						additionalConditionsList.map((line, index) => (
							<div key={index} className="sm:col-span-2">
								<div className="sm:col-span-2">
									<p className="mt-2 text-sm">
										<b>{line.title}</b>
									</p>
								</div>
								<div>
									<p className="mt-2 text-sm">{line.text}</p>
								</div>
							</div>
						))
					) : (
						<p className="mt-2 text-sm">
							<b>NONE</b>
						</p>
					)}
				</div>
			</ScrollPanel>
			{error && (
				<p className="mt-2 text-sm text-red-600" id="email-error">
					{error}
				</p>
			)}
		</div>
	)
}
