import { Image, Link, Text, View } from '@react-pdf/renderer'
import * as creditorwatch from 'assets/creditorwatch.jpg'
import * as layher from 'assets/layher.png'
import * as veda from 'assets/veda.jpg'
import * as qr from 'assets/qr.png'
import { stylesFooter } from './styles'

export const Footer = () => {
	return (
		<View style={stylesFooter.footer}>
			<>
				<Image style={stylesFooter.logo} src={layher.default} />
				<Image style={stylesFooter.logo} src={creditorwatch.default} />
				<Image style={stylesFooter.logo} src={veda.default} />
			</>
			<View style={stylesFooter.contact}>
				<Text style={{ paddingRight: 80 }}>
					<Link
						style={stylesFooter.link}
						src="https://www.facebook.com/myscaffold">
						Facebook
					</Link>
					{` | `}
					<Link
						style={stylesFooter.link}
						src="https://www.youtube.com/channel/UCFUdcH2kkJRUu0NAqQoR7rA">
						YouTube
					</Link>
					{` | `}
					<Link
						style={stylesFooter.link}
						src="https://www.myscaffold.com.au/wp-content/uploads/2014/06/Brochure.pdf">
						Brochure
					</Link>
				</Text>
				<Image
					style={{ ...stylesFooter.logo, width: '40%' }}
					src={qr.default}
				/>
			</View>
		</View>
	)
}
