import { Dropdown } from 'primereact/dropdown'
import { OptionsForDropdown, numberFormat } from 'utilities'
import {
	IQuoteHireLine,
	IQuoteZones,
	IRates,
	IQuoteHireLineFunctions,
	IQuoteForm,
} from 'models'
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Button } from 'primereact/button'
import { ConfirmationDialog, TextArea } from 'common'
import '../customStyle.css'
import { FormikProps } from 'formik'
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid'

interface QuoteHireLineProps {
	zones: IQuoteZones[]
	rates: IRates[]
	data: IQuoteHireLine
	lineFunctionProvider: IQuoteHireLineFunctions
	index: number
	formik: FormikProps<IQuoteForm>
}

export const QuoteHireLine = ({
	zones,
	rates,
	data,
	index,
	lineFunctionProvider,
	formik,
}: QuoteHireLineProps) => {
	const handleProductLineChange = (
		index: number,
		field: keyof IQuoteHireLine,
		value: string | number | boolean
	) => {
		lineFunctionProvider.update(index, field, value)
	}

	const descriptionOptions = [
		{ value: 'Scaffold Structure' },
		{ value: 'Mobile Scaffold' },
	]

	return (
		<tr>
			<td className="px-0.5 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="zone_id"
					options={OptionsForDropdown(zones, 'zone_id', 'zone_label')}
					id={`zone_id_${data.id}`}
					value={data.zone_id}
					onChange={(e) =>
						handleProductLineChange(index, 'zone_id', e.target.value)
					}
					className={`w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${
						formik?.errors?.quote_hire_lines &&
						(formik.errors.quote_hire_lines as any)[index]?.zone_id
							? 'ring-red-500 ring-1 animate-pulse'
							: ''
					}`}
				/>
			</td>
			<td className="px-0.5 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="Type"
					options={OptionsForDropdown(rates, 'service', 'service')}
					id={`type_${data.id}`}
					value={data.type}
					onChange={(e) =>
						handleProductLineChange(index, 'type', e.target.value)
					}
					className={`w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${
						formik?.errors?.quote_hire_lines &&
						(formik.errors.quote_hire_lines as any)[index]?.type
							? 'ring-red-500 ring-1 animate-pulse'
							: ''
					}`}
				/>
			</td>
			<td className="px-2 py-1 h-8 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
				<Dropdown
					id={`description_${data.id}`}
					options={OptionsForDropdown(descriptionOptions, 'value', 'value')}
					className={`h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center ${
						formik?.errors?.quote_hire_lines &&
						(formik.errors.quote_hire_lines as any)[index]?.description
							? 'ring-red-500 ring-1 animate-pulse'
							: ''
					}`}
					value={data.description}
					onChange={(e) =>
						handleProductLineChange(index, 'description', e.target.value)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`length_${data.id}`}
					type="number"
					min={0}
					className={`h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center ${
						formik?.errors?.quote_hire_lines &&
						(formik.errors.quote_hire_lines as any)[index]?.length
							? 'ring-red-500 ring-1 animate-pulse'
							: ''
					}`}
					value={data.length ? data.length : ''}
					onChange={(e) =>
						handleProductLineChange(index, 'length', Number(e.target.value))
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`width_${data.id}`}
					type="number"
					min={0}
					className={`h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center ${
						formik?.errors?.quote_hire_lines &&
						(formik.errors.quote_hire_lines as any)[index]?.width
							? 'ring-red-500 ring-1 animate-pulse'
							: ''
					}`}
					value={data.width ? data.width : ''}
					onChange={(e) =>
						handleProductLineChange(index, 'width', Number(e.target.value))
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`height_${data.id}`}
					type="number"
					min={0}
					className={`h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center ${
						formik?.errors?.quote_hire_lines &&
						(formik.errors.quote_hire_lines as any)[index]?.height
							? 'ring-red-500 ring-1 animate-pulse'
							: ''
					}`}
					value={data.height ? data.height : ''}
					onChange={(e) =>
						handleProductLineChange(index, 'height', Number(e.target.value))
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`multiplier_factor${data.id}`}
					type="number"
					min={0}
					step={0.01}
					className={`h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center ${
						formik?.errors?.quote_hire_lines &&
						(formik.errors.quote_hire_lines as any)[index]?.multiplier_factor
							? 'ring-red-500 ring-1 animate-pulse'
							: ''
					}`}
					value={data.multiplier_factor ? data.multiplier_factor : ''}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'multiplier_factor',
							Number(e.target.value)
						)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`quantity${data.id}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
					value={data.quantity ? data.quantity : ''}
					onChange={() => null}
					disabled
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<div className="flex justify-start items-center">
					{!formik.values.quote_hire_lines?.[index]?.is_manually_entered && (
						<LockClosedIcon
							onClick={() => {
								handleProductLineChange(index, 'is_manually_entered', true)
							}}
							className="flex-shrink-0 h-4 w-4 text-red-500"
						/>
					)}
					{formik.values.quote_hire_lines?.[index]?.is_manually_entered && (
						<LockOpenIcon
							onClick={() => {
								handleProductLineChange(index, 'is_manually_entered', false)
							}}
							className="flex-shrink-0 h-4 w-4 text-green-500"
						/>
					)}
					<input
						id={`weekly_hire_fee${data.id}`}
						type="number"
						className="h-8 ml-2 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
						value={data.weekly_hire_fee ? data.weekly_hire_fee : ''}
						// onBlur={(e) => {
						// 	handleProductLineChange(
						// 		index,
						// 		'weekly_hire_fee',
						// 		Number(e.target.value)?.toFixed(2)
						// 	)
						// }}
						onChange={(e) =>
							handleProductLineChange(
								index,
								'weekly_hire_fee',
								Number(e.target.value)
							)
						}
						disabled={
							!formik.values.quote_hire_lines?.[index]?.is_manually_entered
						}
					/>
				</div>
			</td>
			{/* <td className="px-2 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-12">
				<div className="flex w-full h-full text-center justify-center">
					<ConfirmationDialog
						icon="new"
						title="Hire Condition"
						body="Enter the hire conditions for this line item"
						cancelButtonText="Close"
						children={
							<TextArea
								title="Hire Conditions"
								id={`hire_condition${data.id}`}
								placeholder="Enter your conditions, saves automatically"
								handleChange={(e) =>
									handleProductLineChange(
										index,
										'hire_condition',
										e.target.value
									)
								}
								type="text"
								value={data.hire_condition}
								rows={10}
							/>
						}
						triggerButton={
							<button type="button">
								<PencilSquareIcon
									className="flex-shrink-0 h-4 w-4 text-blue-500"
									aria-hidden="true"
								/>
							</button>
						}
						confirmButton={<></>}
					/>
				</div>
			</td> */}
			<td className="px-2 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-8">
				<div className="flex w-full h-full text-center justify-center">
					<ConfirmationDialog
						icon="danger"
						title="Delete Line Item"
						body="Are you sure you want to delete this item? This action is unrecoverable!"
						triggerButton={
							<button type="button">
								<XCircleIcon
									className="flex-shrink-0 h-4 w-4 text-red-500"
									aria-hidden="true"
								/>
							</button>
						}
						confirmButton={
							<Button
								className="bg-red-600 text-white"
								onClick={async () => lineFunctionProvider.remove(index)}>
								Delete Line
							</Button>
						}
					/>
				</div>
			</td>
		</tr>
	)
}
