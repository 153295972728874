import { IQuoteHireLine, IQuoteZones, IRates } from 'models'

const initLine: IQuoteHireLine = {
	id: null,
	zone_id: 1,
	zone_label: 'Area 1',
	type: 'Hire Per Week (minimum 1 week hire)',
	description: 'Scaffold Structure',
	length: null,
	height: null,
	width: null,
	multiplier_factor: 1,
	quantity: null,
	weekly_hire_fee: 0,
	hire_condition: '',
	is_manually_entered: false,
}

const add = (quote_lines: IQuoteHireLine[]): IQuoteHireLine[] => {
	quote_lines.push(initLine)
	return quote_lines
}

const remove = (
	index: number,
	quote_lines: IQuoteHireLine[]
): IQuoteHireLine[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const update = (
	index: number,
	quote_lines: IQuoteHireLine[],
	field: string,
	value: string | number | boolean
): IQuoteHireLine[] => {
	const newQuoteLines: IQuoteHireLine[] = quote_lines.map((line, i) => {
		if (i === index) {
			const newLine = {
				...line,
				[field]: value,
			}
			// if (field === 'type') {
			// 	newLine.description = newLine.type
			// }
			return newLine
		}
		return line
	})
	return newQuoteLines
}

const calculate = (
	rates: IRates[],
	quote_lines: IQuoteHireLine[]
): IQuoteHireLine[] => {
	const newQuoteLines = quote_lines.map((line) => {
		if (line.type == '') line.type = 'Weekly Hire'
		const rate = rates.find((rate) => rate.service === line.type)?.fee
		if (!rate) return line

		// console.log('skipping calculation')
		const calculatedQuantity = line.length! * line.height! * line.width!
		const withMultiplier = calculatedQuantity * line.multiplier_factor!
		const total = withMultiplier * rate
		const newLine: IQuoteHireLine = {
			...line,
			quantity: Math.round(calculatedQuantity * 100) / 100,
			weekly_hire_fee: Math.round(total * 100) / 100,
		}
		if (newLine.is_manually_entered == false) return newLine
		else return line
	})
	return newQuoteLines
}

const checkZones = (
	zones: IQuoteZones[],
	quote_lines: IQuoteHireLine[]
): IQuoteHireLine[] => {
	const newQuoteLines = quote_lines.map((line) => {
		const zone = zones.find((zone) => zone.zone_id === line.zone_id)

		if (!zone) {
			line.zone_id = null
			line.zone_label = ''
		} else {
			line.zone_label = zone.zone_label
		}
		return line
	})

	return newQuoteLines
}

export const quoteHireLineFunctions = {
	add,
	remove,
	update,
	calculate,
	checkZones,
}
