import { Font, StyleSheet } from '@react-pdf/renderer'

Font.register({
	family: 'Open Sans',
	src: 'https://fonts.gstatic.com/s/opensans/v23/mem8YaGs126MiZpBA-UFVZ0e.ttf',
})

export const stylesFooter = StyleSheet.create({
	footer: {
    position: 'absolute',
    bottom: 10,
    left: 50,
    right: 0,
    display: 'flex',
		paddingBottom: 10,
    alignItems: 'center',
    flexDirection: 'row',
  },
  logo: {
		objectFit: 'contain',
		height: 'auto',
		width: '20%',
	},
  contact: {
    fontFamily: 'Open Sans',
    paddingLeft: 108,
    paddingRight: 50,
		fontSize: '8',
    flexDirection: 'row',
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none',
		fontWeight: 'bold',
    color: 'black'
  }
})
