import { Image, Link, Text, View } from '@react-pdf/renderer'
import { AppConfig } from 'config'
import * as logo from 'assets/logo.png'
import { stylesHeading } from './styles'

export const Heading = () => {
	return (
		<View style={stylesHeading.headingContainer}>
			<View style={stylesHeading.headingContact}>
				<View style={stylesHeading.headingDisplay}>
					<Text style={stylesHeading.headingText}>
						Direct: 0408 55 77 55
						{'\n'}Office: 1300 MYSCAF (697 223)
						{'\n'}Email: matt@myscaffold.com.au
						{'\n'}Web: <Link src='www.myscaffold.com.au'>www.myscaffold.com.au</Link>
						{'\n'}Warehouse: 1/128 Ham Street, South Windsor 2756, New South Wales, Australia
					</Text>
					<Text style={stylesHeading.subText}></Text>
				</View>
			</View>
			<Image style={stylesHeading.logo} src={logo.default} />
		</View>
	)
}
