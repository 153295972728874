import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { ApproveInvoices, DeleteInvoice, XeroStatus } from 'components/Invoices'
import { InvoiceServices, DataTableServices, ClientServices } from 'services'
import { Container, DataTableHeader, PageHeading, Spinner } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { numberFormat } from 'utilities'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'

export const MainInvoicesTable = () => {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } =
		InvoiceServices.useInvoices('Pending')
	const { data: clients, isLoading: clientsLoading } =
		ClientServices.useClients()
	const [invoicesSelected, setInvoicesSelected] = useState(null)

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'invoiceType',
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterInitialValue: '',
				filterOptions: [],
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
			},
		],
		additionalGlobalFilterFields: [
			'quote_id',
			'job_id_searchable',
			'client_name',
			'jobData.site',
			'description',
			'job_name',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	console.log(data)

	if (!data || !clients) return <Spinner />

	const dataSearchable = data?.map((item: any, index: number) => {
		const client = clients?.find(
			(client: any) => Number(client.id) === Number(item.jobData.client_id)
		)
		return {
			...item,
			quote_id: (Number(item.jobData.quote_id) + 1000).toString(),
			client_name: client.client_name,
			job_id_searchable: (Number(item.jobData.id) + 1000).toString(),
			job_name: item.jobData.name,
		}
	})

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const headerRow = (data: any) => (
		<span className="text-gray-900 font-bold">{`Job #${
			data?.jobData?.job_num
		}/Quote #${Number(data?.jobData?.quote_id) + 1000 || 'N/A'} - ${
			data.job_name || 'N/A'
		} - ${data.client_name} - ${data?.jobData?.site}`}</span>
	)

	return (
		<div className="card">
			<PageHeading title="Invoices" />
			<div className="mb-4 mx-2">
				<XeroStatus
					setLoggedIn={() => {
						null
					}}
				/>
			</div>
			<div className="px-8 flex justify-start space-x-4">
				<ApproveInvoices
					invoicesSelected={invoicesSelected}
					setInvoicesSelected={setInvoicesSelected}
				/>
				<ApproveInvoices
					invoicesSelected={invoicesSelected}
					setInvoicesSelected={setInvoicesSelected}
					endOfMonth
				/>
			</div>
			<br />
			<div className="px-8"></div>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={dataSearchable}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading || clientsLoading}
					dataKey="index"
					groupRowsBy="job_id"
					sortField="job_id"
					sortOrder={-1}
					rowGroupMode="subheader"
					rowGroupHeaderTemplate={headerRow}
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					selection={invoicesSelected}
					onSelectionChange={(e) => setInvoicesSelected(e.value)}
					emptyMessage="No Invoices found.">
					<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
					<Column field="invoiceType" header="Invoice Type" />
					<Column field="zone_label" header="Area Label" />
					<Column field="type" header="Type" />
					<Column field="description" header="Description" />
					<Column field="on_hire" header="On Hire" />
					<Column field="erect_percent" header="% Erect" />
					<Column field="date_on_hire" header="Date On Hire" />
					<Column field="completed_date" header="Date Completed" />
					<Column field="days_on_hire" header="Days on Hire" />
					<Column
						field="weekly_hire_rate"
						header="Weekly Hire Rate"
						body={(row: { weekly_hire_rate: number }) => {
							return numberFormat.format(row.weekly_hire_rate || 0) || ''
						}}
					/>
					<Column
						field="total"
						header="Total"
						body={(row: { total: number }) => {
							return numberFormat.format(row.total || 0) || ''
						}}
					/>
					<Column
						field="quote_id"
						header="Quote"
						body={(row: { quote_id: string }) => {
							return `#${row.quote_id}`
						}}
					/>
					{/* <Column
						field="handover_url"
						header="Handover Certificate"
						body={(row: { handover_url: string }) => {
							if (row.handover_url) {
								return (
									<a href={row.handover_url} target="_blank" rel="noreferrer">
										Link
									</a>
								)
							}
							return <></>
						}}
					/> */}
					<Column
						field="updatedAt"
						header="Last Time updated"
						body={(row: { updatedAt: string }) => {
							return new Date(row.updatedAt).toLocaleDateString('en-NZ')
						}}
					/>
					<Column
						header="Edit"
						body={(rowData) => {
							return (
								<Link
									to={{
										pathname: AppRoutes.privateRoutes.editInvoice
											.replace(':id', rowData.id)
											.replace(
												':invoiceType',
												rowData.invoiceType === 'Weekly Hire'
													? 'weekly-hire'
													: 'ed-nvoice'
											),
									}}
									state={{ background: location, name: 'editInvoice' }}>
									<PencilSquareIcon className="h-4 w-4 text-gray-500" />
								</Link>
							)
						}}
					/>
					{enableCreateUpdate && (
						<Column
							header="Delete"
							body={(row) => <DeleteInvoice invoice={row} />}
						/>
					)}
				</DataTable>
			</Container>
		</div>
	)
}
