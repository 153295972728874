import { ClientServices, VisitServices } from 'services'
import EventView from './EventContent'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

export function JobVisitsSchedulerUtils() {
	const location = useLocation()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventContent = (view: any) => (
		<EventView event={view.event} location={location} />
	)
	const { data: visitsData, isLoading: visitsLoading } =
		VisitServices.useVisits()
	const filteredVisitData = visitsData?.filter(
		(visit: { status: string }) => visit.status === 'Active'
	)
	const { data: clientData, isLoading: clientLoading } =
		ClientServices.useClients()
	const { updateVisit } = VisitServices.useUpdateVisit()
	const headerToolbar = {
		left: 'title',
		right: 'prev,today,next',
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventDrop = async ({ event }: any) => {
		const visitId = Number(event?.id)
		const newDate = moment(event?.start).format('DD/MM/YYYY')
		if (visitId && newDate) {
			const payload = {
				date: newDate,
			}
			await updateVisit(visitId, payload)
		}
	}

	const formatEvents = () => {
		if (!visitsLoading && filteredVisitData) {
			return filteredVisitData.map((visit: any) => {
				const staffNames = visit?.staff_info.map(
					(item: { staff_name: string }) => item.staff_name
				)
				console.log('visit', visit)
				const clientid = visit?.jobData?.client_id
				const client = clientData?.find(
					(item: { id: number }) => Number(item.id) === Number(clientid)
				)
				return {
					id: visit.id,
					resourceId: visit.teamLeaderData?.id,
					job_id: String(Number(visit?.jobData?.quote_id) + 1000),
					title: visit.visit_status,
					teamLeader: visit.teamLeaderData?.staff_name || '',
					type: visit?.type,
					site: visit.jobData?.site,
					staff: staffNames.join(', '),
					start: moment(visit?.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
					start_date: visit?.date,
					start_time: visit?.time_on,
					client_name: client?.client_name,
					is_event: visit?.is_event,
					event_title: visit?.title,
					description: visit?.description,
				}
			})
		}
		return []
	}

	return {
		formatEvents,
		visitsData,
		visitsLoading,
		headerToolbar,
		eventDrop,
		eventContent,
	}
}
