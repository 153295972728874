import { AppRoutes } from 'config'
import { Container, TaskContainer } from './styledComponents'
import { Link, Location } from 'react-router-dom'

interface EventProps {
	type: string
	job_id: number
	teamLeader: string
	site: string
	staff: string
	client_name: string
	start_time: string
	start_date: string
	is_event: boolean
	event_title: string
	description: string
}

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	event: any
	location: Location
}

const EventView = ({ event, location }: IProps) => {
	const color = {
		Install: '#18ab33',
		Dismantle: '#de1f2f',
		Adjustment: '#EF4444',
		Remedial: '#fbb724',
		Variation: '#9e45f7',
		Move: '#3b7aed',
		'Dry Hire Delivery': '#3bed6b',
		'Dry Hire Pickup': '#ea3bed',
		Notes: '#eded3b',
	}
	const eventProp: EventProps = event.extendedProps
	const { publicId: visitId } = event._def
	const styles = () => ({
		color: 'white',
		backgroundColor: eventProp.is_event
			? 'gray'
			: color[eventProp.type as keyof typeof color] || '#10B981',
	})

	console.log('eventPropeventProp', eventProp)

	return (
		<Link
			to={{
				pathname: AppRoutes.privateRoutes.visitsEdit.replace(
					':id',
					visitId?.split('-')?.[0]?.toString()
				),
			}}
			state={{
				background: location,
				name: 'editVisit',
				isEvent: eventProp.is_event,
			}}>
			<Container
				padding="0.5px"
				fontSize="0.8rem"
				color={styles().color}
				backgroundColor={styles().backgroundColor}>
				{!eventProp.is_event ? (
					<>
						<TaskContainer>
							<b>Quote #:</b> {eventProp.job_id}
						</TaskContainer>
						<TaskContainer>
							<b>Client:</b> {eventProp.client_name}
						</TaskContainer>
						<TaskContainer>
							<b>Site:</b> {eventProp.site}
						</TaskContainer>
						<TaskContainer>
							<b>Start/Delivery:</b> {eventProp.start_date} -{' '}
							{eventProp.start_time}
						</TaskContainer>
					</>
				) : (
					<>
						<TaskContainer>
							<b>Title:</b> {eventProp.event_title}
						</TaskContainer>
						<TaskContainer>
							<b>Description:</b> {eventProp.description}
						</TaskContainer>
						<TaskContainer>
							<b>Start:</b> {eventProp.start_date} - {eventProp.start_time}
						</TaskContainer>
					</>
				)}
			</Container>
		</Link>
	)
}

export default EventView
