import React, { useEffect, useState } from 'react'
import { Checkbox } from 'common' // Import the updated Checkbox component

interface CheckboxOption {
	type: string
	title: string
	checked: boolean
}

interface TickBoxProps {
	sharedCheckBoxes: CheckboxOption[]
	setsharedCheckBoxes: any
}

const TickBox: React.FC<TickBoxProps> = ({
	sharedCheckBoxes,
	setsharedCheckBoxes,
}) => {
	const handleCheckboxChange = (id: string, values: string[]) => {
    const updatedState = sharedCheckBoxes.map((cb) => {
      if (values.includes(cb.title)) {
        return { ...cb, checked: true };
      } else if (cb.type === 'General Requirement' && id === 'generalRequirement') {
        return { ...cb, checked: false };
      } else if (cb.type === 'Additional Condition' && id === 'additionalConditions') {
        return { ...cb, checked: false };
      }
      return cb;
    });

    setsharedCheckBoxes(updatedState);
  };

	const options = sharedCheckBoxes.map((cb) => ({
		value: cb.title,
		label: cb.title,
		type: cb.type,
	}))

	const selectedRequirements = sharedCheckBoxes
		.filter((cb) => cb.checked && cb.type === 'General Requirement')
		.map((cb) => cb.title)

	const selectedConditions = sharedCheckBoxes
		.filter((cb) => cb.checked && cb.type === 'Additional Condition')
		.map((cb) => cb.title)

	return (
		<div className="grid gap-4">
			<br />
			<h3 className="text-lg px-4 leading-6 font-large">Select Requirements</h3>
			<Checkbox
				title=""
				options={options.filter((opt) => opt.type === 'General Requirement')}
				id="generalRequirement"
				values={selectedRequirements}
				onChange={handleCheckboxChange}
			/>
			<br />
			<h3 className="text-lg px-4 leading-6 font-large">
				Select Conditions
			</h3>
			<Checkbox
				title=""
				options={options.filter((opt) => opt.type === 'Additional Condition')}
				id="additionalConditions"
				values={selectedConditions}
				onChange={handleCheckboxChange}
			/>
		</div>
	)
}

export default TickBox
