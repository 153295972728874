import { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { VisitsComponents } from 'components'

export const VisitsEdit = () => {
	const [openVisitsForm, setOpenVisitsForm] = useState(true)
	const navigate = useNavigate()
	const location = useLocation()
	const { isEvent } = location.state || {}

	const { id } = useParams()

	useEffect(() => {
		if (!openVisitsForm) {
			navigate(-1)
		}
	}, [openVisitsForm])

	return (
		<VisitsComponents.VisitForm
			formType="update"
			heading={!isEvent ? 'Edit Visit' : 'Edit Event'}
			open={openVisitsForm}
			setOpen={setOpenVisitsForm}
			visit_id={Number(id)}
			isEvent={isEvent}
		/>
	)
}
