import { IQuoteLabourLine, IQuoteZones, IRates } from 'models'

const initLine: IQuoteLabourLine = {
	id: null,
	zone_id: 1,
	zone_label: 'Area 1',
	type: '',
	description: '',
	labour_hours: null,
	labour_cost: null,
	labour_condition: '',
	is_manually_entered: false,
}

const add = (quote_lines: IQuoteLabourLine[]): IQuoteLabourLine[] => {
	quote_lines.push(initLine)
	return quote_lines
}

const remove = (
	index: number,
	quote_lines: IQuoteLabourLine[]
): IQuoteLabourLine[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const update = (
	index: number,
	quote_lines: IQuoteLabourLine[],
	field: string,
	value: string | number | boolean
): IQuoteLabourLine[] => {
	const newQuoteLines: IQuoteLabourLine[] = quote_lines.map((line, i) => {
		if (i === index) {
			const newLine = {
				...line,
				[field]: value,
			}
			if (field === 'type') {
				newLine.description = newLine.type
			}
			return newLine
		}
		return line
	})
	return newQuoteLines
}

const calculate = (
	rates: IRates[],
	quote_lines: IQuoteLabourLine[]
): IQuoteLabourLine[] => {
	const newQuoteLines = quote_lines.map((line) => {
		const rate = rates.find(
			(rate) => rate.service.toLowerCase().trim() === line.type.toLowerCase()
		)?.fee
		if (!rate) return line

		const labour_cost = Number(line.labour_hours) * Number(rate)
		if (line.is_manually_entered == false) {
			line.labour_cost = Math.round(labour_cost * 100) / 100
		}
		return line
	})
	return newQuoteLines
}

const checkZones = (
	zones: IQuoteZones[],
	quote_lines: IQuoteLabourLine[]
): IQuoteLabourLine[] => {
	const newQuoteLines = quote_lines.map((line) => {
		const zone = zones.find((zone) => zone.zone_id === line.zone_id)

		if (!zone) {
			line.zone_id = null
			line.zone_label = ''
		} else {
			line.zone_label = zone.zone_label
		}
		return line
	})

	return newQuoteLines
}

export const quoteLabourLineFunctions = {
	add,
	remove,
	update,
	calculate,
	checkZones,
}
