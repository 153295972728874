export const RequirementsAndConditions = [
	{
		title: 'AFTER HOURS',
		text: "This quote has been based on Labour to take place OUTSIDE normal hours, normal hours are from 7am to 3pm Monday to Friday excluding public holidays and rdo's",
		type: 'General Requirement',
		checked: false,
	},
	{
		title: 'NORMAL HOURS',
		text: "This quote has been based on Labour to take place during normal hours, normal hours are from 7am to 3pm Monday to Friday excluding public holidays and rdo's",
		type: 'General Requirement',
		checked: false,
	},
	{
		title: '30 DAYS',
		text: 'This quote is valid for 30 days from the date of issue and subject to availability of equipment.',
		type: 'General Requirement',
		checked: false,
	},
	{
		title: 'COMPLY TO WORKSAFE',
		text: 'All scaffolds will be erected in accordance with AS/NZS 1576.1:2010 scaffold general requirements.',
		type: 'General Requirement',
		checked: false,
	},
	{
		title: 'CLEAR/UNOBSTRUCTED/FREE PARKING FOR ERECT AND DISMANTLE',
		text: 'Price assumes clear and unobstructed access to site, including parking for truck during erection & dismantle.',
		type: 'General Requirement',
		checked: false,
	},
	{
		title: 'DAMAGE OR DIRTY',
		text: 'Equipment returned damaged or dirty, will incur additional cost for cleaning or repair.',
		type: 'General Requirement',
		checked: false,
	},
	{
		title: 'EXTRA LABOUR $75 PER HR',
		text: 'Any additional work or alterations that are not detailed in this quote will be extra to contract and charged at $75.00 + GST per man, per hour on a sign time basis.',
		type: 'General Requirement',
		checked: false,
	},
	{
		title: 'MINIMUM 1 WEEK HIRE',
		text: 'This quote is subject to a one week minimum hire period. (Daily Hire Rate x 7 days).',
		type: 'General Requirement',
		checked: false,
	},
	{
		title: 'POWER LINES',
		text: 'In the event that power lines will fall within 4 metres of scaffolding being built, the client will need to arrange with local energy provider for a Close Proximity Permit / tiger tails and or insulation, and pay any associated costs prior to the install date.',
		type: 'General Requirement',
		checked: false,
	},
	{
		title: 'WEATHER',
		text: 'We can only install/dismantle the scaffold in suitable weather conditions and will not be liable for any loss incurred for delays caused by weather. This includes any adverse weather leading up to the agreed installation date that has delayed other jobs.',
		type: 'General Requirement',
		checked: false,
	},
	{
		title: 'TIES / ANCHORS',
		text: 'Scaffold will be tied to building/house with drilled in anchors. Client is responsible for arranging holes to be filled and touched up.',
		type: 'General Requirement',
		checked: false,
	},
	{
		title: 'AERIAL SIGNALS',
		text: 'Our Organisation will take all practical care but will not be responsible for damage, or interference of signal, to TV Aerials or Sky Dishes.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'BARRIERS',
		text: "It is the client's responsibility to provide vehicle impact protection to the base of the scaffold to protect the structure against impact by a vehicle. This can be done with the use of speed rated water-filled barriers or concrete barriers in temporary applications.",
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'COSTS ARE SUBJECT TO A ENGINEERS REPORT',
		text: "This quote is subject to an engineer's report to establish required loads on the supporting surface, design applications therefore pricing stated in this quote may vary upon information being provided.",
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'COUNCIL PERMITS LOCAL AUTHORITY',
		text: 'All council permits and or local authority requirements to be obtained by the client prior to installation of the scaffold.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'CRANE LIFT COST BY CLIENT',
		text: "Crane lifts required for this project are to be arranged by the client and are at the client's cost and are not included in this quote.",
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'CREDIT CARD PAYMENTS',
		text: 'We can accept credit card payments with a 1.87% additional fee.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'DRIVEWAY BLOCKED BY SCAFFOLD',
		text: 'Driveway will be blocked by scaffold.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'ENGINEER',
		text: 'If an engineer design and or a report is required, this fee is in addition to this quote.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'GARAGE BLOCKED',
		text: 'Garage/Carport will be blocked by scaffold.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'GARDENS',
		text: 'Our Organisation will take all practical care but will not be responsible for damage to the gardens.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'INDUCTION ALLOCATION OF 30MINS ON SITE MAX',
		text: 'A provision has been made in this quote for a site induction of up to 30 minutes on the same site and at the same time as the works are to be carried out. In the event of inductions taking place at a different location and or a different time to the works, extra charges will apply based on an hourly rate of $75.00+GST per man per hour. Chargeable hours will include travel time. Any type of inductions that incur charges such as online payments, tokens, or third-party compliance will be extra to contract.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'LEVEL/COMPACTED',
		text: 'Excavations to be filled, compacted, and levelled before scaffold is erected.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'NEIGHBOURS PERMISSION BY CLIENT',
		text: 'Client to arrange permission from neighbours to build/land scaffold on neighbouring property.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'OBSTRUCT STAIRS / WALKWAY / FIRE ESCAPE',
		text: 'The scaffold will obstruct stair/walkway access for the duration of works; it is the responsibility of the client to organise signage, alternate access, or fire escape plans.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'RETENTIONS DO NOT APPLY',
		text: 'Retentions will not apply to this contract.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'ROOF DAMAGE',
		text: 'Our Organisation will take all practical care but will not be responsible for damage to roofs.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'SECURITY',
		text: 'Client will be responsible for any security measures required while scaffold is in place.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'STAND DOWN CHARGES APPLY',
		text: 'Stand down charges will apply for the initial day before and the final day after any work carried outside normal hours of work where a 10-hour break cannot be possible. For example, if work is requested to commence on a Tuesday night from 8pm to 3am base to base, 8 hours normal time will be applicable for Monday and Wednesday in addition to the 8 hours at double time for the night work.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'SUBJECT TO SITE INSPECTION',
		text: 'This quote has been prepared based on information/plans/photos provided by the client; therefore, rates quoted are subject to a site inspection.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'TEMPORARY FENCING',
		text: "It is the client's responsibility to provide temporary fencing to contain the work area from unauthorized access to the scaffold or material storage area.",
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'TIES / WINDOWS',
		text: "Ties will need to be fixed through the window openings to secure the scaffold. It is the client's responsibility to ensure the windows are unlocked and can be opened from the outside or left open.",
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'TREES',
		text: 'Trees/Plants/Gardens must be trimmed by client to make space for scaffold to be erected.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'WAITING TIME BEYOND OUR CONTROL',
		text: 'Any waiting time that is beyond our control, such as lift access, dock access, loading or unloading access, is deemed as extra to contract and is chargeable on a sign time basis $75.00+GST per man per hour.',
		type: 'Additional Condition',
		checked: false,
	},
	{
		title: 'WIND OVER 80KMH PRODUCT NOT GUARANTEE',
		text: 'We do not guarantee the product against wind gusts over 80kmh as recorded by www.australia.metservice.com.',
		type: 'Additional Condition',
		checked: false,
	},
]
