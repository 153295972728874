export const visitTypeOptions = [
	{
		value: 'Install',
		label: 'Install',
	},
	{
		value: 'Dismantle',
		label: 'Dismantle',
	},
	{
		value: 'Adjustment',
		label: 'Adjustment',
	},
	{
		value: 'Remedial',
		label: 'Remedial',
	},
	{
		value: 'Variation',
		label: 'Variation',
	},
	{
		value: 'Move',
		label: 'Move',
	},
	{
		value: 'Dry Hire Delivery',
		label: 'Dry Hire Delivery',
	},
	{
		value: 'Dry Hire Pickup',
		label: 'Dry Hire Pickup',
	},
	{
		value: 'Notes',
		label: 'Notes',
	},
]

export const visitsStatusOptions = [
	{ label: 'Pending Prestart', value: 'Pending Prestart' },
	{ label: 'Pending Close Of Visit', value: 'Pending Close Of Visit' },
	{ label: 'Completed', value: 'Completed' },
]
