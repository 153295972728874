import { useContext, useEffect, useRef } from 'react'
import { Button, Checkbox, Spinner, Tabs, TextArea } from 'common'
import { QuoteServices, JobsServices } from 'services'
import {
	ZonesForm,
	RatesForm,
	QuoteHireLinesForm,
	QuoteLabourLinesForm,
	QuoteAdditionalLinesForm,
	Totals,
} from '../FormComponents'
import { useState } from 'react'
import { GeneralInfo } from '../FormComponents/GeneralInfo'
import { RatesAdmin } from '../FormComponents/Rates/RatesAdmin'
import SiteFiles from '../FormComponents/SiteFiles/SiteFiles'
import { NotificationsContext } from 'context/notifications/toastContext'
import { RequirementsConditions } from '../FormComponents/RequirementsConditions'
import TickBox from '../FormComponents/Tickbox/Tickbox'
import { RequirementsAndConditions } from './RequirementsAndConditions'

interface FormProps {
	quoteID?: number
}

interface CheckboxOption {
	title: string
	text: string
	type: string
	checked: boolean
}

export const Form = ({ quoteID }: FormProps) => {
	const { showMultipleErrors } = useContext(NotificationsContext)
	const formRef = useRef<HTMLDivElement | null>(null)
	const [tabIndex, setTabIndex] = useState(0)

	const { data: quote_data, isLoading: quote_loading } =
		QuoteServices.useQuoteById(quoteID)

	const { data: quote_hire_lines, isLoading: quote_hire_lines_loading } =
		QuoteServices.useQuoteHireLinesByID(quoteID)

	const { data: quote_labour_lines, isLoading: quote_labour_lines_loading } =
		QuoteServices.useQuoteLabourLinesByID(quoteID)

	const { data: quote_addons, isLoading: quote_addons_loading } =
		QuoteServices.useQuoteAddonsById(quoteID)

	const { data: quote_zones, isLoading: quote_zones_loading } =
		QuoteServices.useQuoteZonesById(quoteID)

	const { data: quote_rates, isLoading: quote_rates_loading } =
		QuoteServices.useQuoteRatesById(quoteID)

	const { data: jobs_data, isLoading: jobs_loading } = JobsServices.useJobs()

	const {
		formik,
		quoteHireLineHandlers,
		quoteLabourLineHandlers,
		quoteAdditionalLinesHandlers,
		ratesHandlers,
		zonesHandlers,
		setBackToMainTable,
		itemsTabs,
	} = QuoteServices.useFormHandler({
		quote_id: quoteID,
		quote_data,
		quote_hire_lines,
		quote_labour_lines,
		quote_addons,
		quote_rates,
		quote_zones,
	})

	const [sharedCheckBoxes, setSharedCheckBoxes] = useState<CheckboxOption[]>([]);

	useEffect(() => {
		const parseCheckboxOptions = (
			textData: string,
			type: string
		): CheckboxOption[] => {
			try {
				const parsedData = JSON.parse(textData)
				return parsedData.map((item: any) => ({
					title: item.title,
					text: item.text,
					type: item.type,
					checked: item.checked || false,
				}))
			} catch (error) {
				return RequirementsAndConditions.filter((option) => option.type === type)
			}
		}

		if (!quote_loading && quote_data) {
			const generalRequirements = quote_data.general_requirements || '[]'
			const additionalConditions = quote_data.additional_conditions || '[]'

			const generalRequirementsOptions = parseCheckboxOptions(
				generalRequirements,
				'General Requirement'
			)
			const additionalConditionsOptions = parseCheckboxOptions(
				additionalConditions,
				'Additional Condition'
			)

			setSharedCheckBoxes([
				...generalRequirementsOptions,
				...additionalConditionsOptions,
			])
		} else if (!quoteID) {
			setSharedCheckBoxes(RequirementsAndConditions)
		}
	}, [quote_loading, quote_data])

  useEffect(() => {
		console.log({sharedCheckBoxes})
		const generalRequirementsOptions = sharedCheckBoxes.filter(
			(option) => option.type === 'General Requirement'
		)
		const additionalConditionsOptions = sharedCheckBoxes.filter(
			(option) => option.type === 'Additional Condition'
		)

		formik.setFieldValue(
			'general_requirements',
			JSON.stringify(generalRequirementsOptions)
		)
		formik.setFieldValue(
			'additional_conditions',
			JSON.stringify(additionalConditionsOptions)
		)
	}, [sharedCheckBoxes])

	if (
		quote_loading ||
		quote_hire_lines_loading ||
		quote_labour_lines_loading ||
		quote_addons_loading ||
		quote_zones_loading ||
		quote_rates_loading ||
		jobs_loading
	) {
		return <Spinner />
	}

	return (
		<>
			<div className="mx-4 mb-10" ref={formRef}>
				<div className="w-full">
					<div className="flex">
						<div className="lg:w-4/6 md:w-full sm:w-full">
							<GeneralInfo formik={formik} jobs_data={jobs_data} />
						</div>
						<div className="w-full">
							<div className="w-4/6 mx-auto">
								<div className="flex justify-start mb-4">
									<Tabs
										tabIndex={tabIndex}
										setTabIndex={setTabIndex}
										tabs={itemsTabs}
									/>
								</div>
								{tabIndex === 0 && (
									<ZonesForm
										zones={formik.values.zones}
										zonesHandlers={zonesHandlers}
										errors={formik.errors}
									/>
								)}
								{tabIndex === 1 && (
									<RatesForm
										rates={formik.values.rates}
										ratesHandlers={ratesHandlers}
									/>
								)}
								{tabIndex === 2 && (
									<RatesAdmin
										rates={formik.values.rates}
										ratesHandlers={ratesHandlers}
									/>
								)}
							</div>
						</div>
					</div>

					<QuoteHireLinesForm
						zones={formik.values.zones}
						rates={formik.values.rates}
						values={formik.values.quote_hire_lines}
						handlerFunctions={quoteHireLineHandlers}
						formik={formik}
					/>

					<QuoteLabourLinesForm
						zones={formik.values.zones}
						rates={formik.values.rates}
						values={formik.values.quote_labour_lines}
						handlerFunctions={quoteLabourLineHandlers}
						formik={formik}
					/>

					<QuoteAdditionalLinesForm
						rates={formik.values.rates}
						additionalLines={formik.values.quote_additional_lines}
						additionalLinesHandlers={quoteAdditionalLinesHandlers}
						formik={formik}
					/>

					<SiteFiles
						setFieldValue={formik.setFieldValue}
						values={formik.values}
					/>

					<div className="flex">
						<div className="lg:w-4/6 md:w-full sm:w-full">
							<RequirementsConditions
								id={''}
								sharedCheckBoxes={sharedCheckBoxes}
								title="Scaffolding General Requirements"
							/>
						</div>
						<div className="lg:w-4/6 md:w-full sm:w-full">
						<TickBox
							sharedCheckBoxes={sharedCheckBoxes}
							setsharedCheckBoxes={setSharedCheckBoxes}
						/>
						</div>
					</div>

					{/* <div className="w-3/6">
					<TextArea
							title="Hire Conditions"
							id="hire_conditions"
							placeholder="Use the edit button on a hire line to start adding hire conditions"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							type="text"
							value={formik.values.quote_hire_lines
								.filter(
									(line) =>
										line.hire_condition != '' &&
										line.description != '' &&
										line.zone_id != null
								)
								.map(
									(line) =>
										'Area: ' +
										String(line.zone_label) +
										' - Description: ' +
										String(line.description) +
										'\n' +
										String(line.hire_condition)
								)
								.join('\n\n')}
							rows={10}
							disabled={true}
						/>
						<TextArea
							title="Labour Conditions"
							id="labour_conditions"
							placeholder="Use the edit button on a labour line to start adding labour conditions"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							type="text"
							value={formik.values.quote_labour_lines
								.filter(
									(line) =>
										line.labour_condition != '' &&
										line.description != '' &&
										line.zone_id != null
								)
								.map(
									(line) =>
										'Area: ' +
										String(line.zone_label) +
										' - Description: ' +
										String(line.description) +
										'\n' +
										String(line.labour_condition)
								)
								.join('\n\n')}
							rows={10}
							disabled={true}
						/>
						<TextArea
							title="Addon Conditions"
							id="addon_conditions"
							placeholder="Use the edit button on an addon to start adding addon conditions"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							type="text"
							value={formik.values.quote_additional_lines
								.filter(
									(line) =>
										line.addon_condition != '' &&
										line.description != '' &&
										line.type != null
								)
								.map(
									(line) =>
										'Description: ' +
										String(line.description) +
										'\n' +
										String(line.addon_condition)
								)
								.join('\n\n')}
							rows={10}
							disabled={true}
						/>
					</div> */}

					<Totals
						totalLabourCost={formik.values.totalLabourCost}
						totalAdditionalItemsCost={formik.values.totalAdditionalItemsCost}
						totalWeeklyHireCost={formik.values.totalWeeklyHireCost}
						total={formik.values.total}
					/>

					{quoteID && (
						<div className="pl-2 mt-6 flex space-x-4">
							<Button
								onClick={() => {
									setBackToMainTable(true)
									formik.handleSubmit()
								}}
								type="submit"
								form="quoteForm"
								//isLoading={quoteCreating}
								size="sm"
								variant={'primary'}>
								Save & Exit
							</Button>
							<Button
								onClick={() => formik.handleSubmit()}
								type="submit"
								form="quoteForm"
								size="sm"
								variant={'primary'}>
								Save & View
							</Button>
						</div>
					)}
					{!quoteID && (
						<div className="pl-2 mt-6">
							<Button
								onClick={() => {
									formik.handleSubmit()
									if (
										formik.errors &&
										Object.keys(formik.errors).length > 0 &&
										formRef.current
									) {
										if ('zones' in formik.errors) {
											delete formik.errors['zones']
											formik.errors.zones = 'Areas are required'
											setTabIndex(0)
										}
										const messages: string[] = ([] as string[]).concat(
											...(Object.values(formik.errors)
												.map((error) =>
													Array.isArray(error) ? error : [error]
												)
												.flat() as string[])
										)
										formRef.current.scrollIntoView({
											behavior: 'smooth',
											block: 'start',
										})

										showMultipleErrors(messages)
									}
								}}
								type="submit"
								form="quoteForm"
								size="sm"
								variant={'primary'}>
								Create Quote
							</Button>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
