import { Document, Page, Text, View, Image, Link } from '@react-pdf/renderer'
import {
	QuoteData,
	IQuoteLine,
	IQuoteAdditionalLine,
	IQuoteHireLine,
	IQuoteLabourLine,
} from 'models'
import { styles } from './Styles'
import { Heading, ClientInfo } from './Heading'
import { Description } from './Description'
import { ColumnTypes, Table } from './Table'
import { Footer } from './Footer'

interface QuotePDFDocumentProps {
	quote_data: QuoteData
	quote_lines: {
		quote_hire_lines: IQuoteHireLine[]
		quote_labour_lines: IQuoteLabourLine[]
	}
	quote_addons: IQuoteAdditionalLine[]
}

export const QuotePDFDocument = ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFDocumentProps) => {
	const total_data: {
		zone_label: string
		description: string
		is_total?: boolean
		total: number
		is_hire_total?: boolean
	}[] = []
	console.log("Hire Lines: ", quote_lines.quote_hire_lines)
	const hire_total = quote_lines.quote_hire_lines
		.map((item) => Number(item.weekly_hire_fee))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
	const labour_total = quote_lines.quote_labour_lines
		.map((item) => Number(item.labour_cost))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
	const addons_total = quote_addons
		.map((item) => item.total_cost)
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
	total_data.push(
		// { zone_label: 'Hire', description: 'Cost', total: hire_total },
		// { zone_label: 'Hire', description: 'GST', total: hire_total * 0.1 },
		{
			zone_label: 'Hire',
			description: 'Total',
			total: hire_total,
			is_total: true,
			is_hire_total: true,
		},
		// {
		// 	zone_label: 'Labour',
		// 	description: 'Cost',
		// 	total: labour_total,
		// },
		// {
		// 	zone_label: 'Labour',
		// 	description: 'GST',
		// 	total: labour_total * 0.1,
		// },
		{
			zone_label: 'Labour',
			description: 'Total',
			total: labour_total,
			is_total: true,
		},
		// {
		// 	zone_label: 'Additional Items',
		// 	description: 'Cost',
		// 	total: addons_total,
		// },
		// {
		// 	zone_label: 'Additional Items',
		// 	description: 'GST',
		// 	total: addons_total * 0.1,
		// },
		{
			zone_label: 'Additional Items',
			description: 'Total',
			total: addons_total,
			is_total: true,
		}
	)

	const parseCheckboxOptions = (textData: string): any => {
		try {
			const parsedData = JSON.parse(textData)
			return parsedData.map((item: any) => ({
				title: item.title,
				text: item.text,
				type: item.type,
				checked: item.checked || false,
			}))
		} catch (error) {
			return textData
		}
	}

	const generalRequirements = parseCheckboxOptions(quote_data.general_requirements)
	const additionalConditions = parseCheckboxOptions(quote_data.additional_conditions)

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Heading />
				<Text style={styles.quoteText}>Quotation</Text>
				<ClientInfo quote={quote_data} />
				<Description description={quote_data.scope_of_work} />
				<View>
					<Text style={styles.heading}>Hire (minimum one week hire is applicable)</Text>
					<Table
						type={ColumnTypes.quote_hire_lines}
						data={quote_lines.quote_hire_lines}
					/>
				</View>
				<View>
					<Text style={styles.heading}>Labour</Text>
					<Table
						type={ColumnTypes.quote_labour_lines}
						data={quote_lines.quote_labour_lines}
					/>
				</View>
				<View>
					<Text style={styles.heading}>Additional Items</Text>
					<Table type={ColumnTypes.quote_addons} data={quote_addons} />
				</View>

				<View>
					<Text style={styles.heading}>Totals</Text>
					<Table type={ColumnTypes.quote_total} data={total_data} />
				</View>

				{/* <Text style={styles.subTextBoldRed}>All prices exclude GST</Text> */}
				<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
					<Link src="https://www.myscaffold.com.au/wp-content/uploads/Terms-of-Trade.pdf">
						View our Terms and Conditions here
					</Link>
				</Text>
				<Text style={{ ...styles.subTextMargin, marginLeft: 4, marginTop: 0 }}>
					<Link src="https://www.myscaffold.com.au/wp-content/uploads/Account-Application-Form.pdf">
					Account application form here
					</Link>
				</Text>
				<Text style={{ ...styles.subTextMargin, marginLeft: 4, marginTop: 0 }}>
					<Link src="https://www.myscaffold.com.au/wp-content/uploads/Credit-Card-Authorisation-Form.pdf">
					Credit card authorization form here
					</Link>
				</Text>
				<Footer />
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				{Array.isArray(generalRequirements) ? (
					<>
						{generalRequirements.filter((requirement) => requirement.checked)
							.length ? (
							<>
								<Text style={styles.heading}>General Requirements</Text>
								{generalRequirements
									.filter((requirement) => requirement.checked)
									.map((requirement) => (
										<Text style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											{requirement.title} - {requirement.text}
										</Text>
									))}
							</>
						) : null}
						<Text style={{ marginBottom: 8 }} />
					</>
				) : (
					generalRequirements?.length > 0 && (
						<>
							<Text style={styles.heading}>General Requirements</Text>
							<Text style={{ ...styles.subTextMargin, marginLeft: 2 }}>
								{generalRequirements}
							</Text>
							<Text style={{ marginBottom: 8 }} />
						</>
					)
				)}
				{Array.isArray(additionalConditions) ? (
					<>
						{additionalConditions.filter((requirement) => requirement.checked)
							.length ? (
							<>
								<Text style={styles.heading}>Additional Conditions</Text>
								{additionalConditions
									.filter((requirement) => requirement.checked)
									.map((requirement) => (
										<Text style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											{requirement.title} - {requirement.text}
										</Text>
									))}
							</>
						) : null}
					</>
				) : (
					additionalConditions?.length > 0 && (
						<>
							<Text style={styles.heading}>Additional Conditions</Text>
							<Text style={{ ...styles.subTextMargin, marginLeft: 2 }}>
								{additionalConditions}
							</Text>
						</>
					)
				)}
				<Footer />
			</Page>
			{(quote_lines.quote_labour_lines.filter(
				(line) =>
					line.labour_condition != '' &&
					line.labour_condition !== null &&
					line.description != '' &&
					line.zone_id != null
			).length > 0 ||
				quote_addons.filter(
					(line) =>
						line.addon_condition != '' &&
						line.addon_condition !== null &&
						line.description != '' &&
						line.type != null
				).length > 0 ||
				quote_lines.quote_hire_lines.filter(
					(line) =>
						line.hire_condition != '' &&
						line.hire_condition !== null &&
						line.description != '' &&
						line.zone_id != null
				).length > 0) && (
				<Page size="A4" style={styles.page}>
					<Heading />
					{quote_lines.quote_labour_lines.filter(
						(line) =>
							line.labour_condition != '' &&
							line.labour_condition !== null &&
							line.description != '' &&
							line.zone_id != null
					).length > 0 && (
						<View>
							<Text style={styles.heading}>Labour Conditions</Text>
							<View style={{ flexDirection: 'column' }}>
								{quote_lines.quote_labour_lines
									.filter(
										(line) =>
											line.labour_condition != '' &&
											line.labour_condition !== null &&
											line.description != '' &&
											line.zone_id != null
									)
									.map((line) => (
										<View style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											<Text style={{ fontWeight: 'bold' }}>
												Area: {line.zone_label}
											</Text>
											<Text>{line.labour_condition}</Text>
										</View>
									))}
							</View>
						</View>
					)}
					{quote_lines.quote_hire_lines.filter(
						(line) =>
							line.hire_condition != '' &&
							line.hire_condition !== null &&
							line.description != '' &&
							line.zone_id != null
					).length > 0 && (
						<View>
							<Text style={styles.heading}>Hire Conditions</Text>
							<View style={{ flexDirection: 'column' }}>
								{quote_lines.quote_hire_lines
									.filter(
										(line) =>
											line.hire_condition !== '' &&
											line.hire_condition !== null &&
											line.description != '' &&
											line.zone_id != null
									)
									.map((line) => (
										<View style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											<Text style={{ fontWeight: 'bold' }}>
												Area: {line.zone_label}
											</Text>
											<Text>{line.hire_condition}</Text>
										</View>
									))}
							</View>
						</View>
					)}
					{quote_addons.filter(
						(line) =>
							line.addon_condition != '' &&
							line.addon_condition !== null &&
							line.description != '' &&
							line.type != null
					).length > 0 && (
						<View>
							<Text style={styles.heading}>Additional Item Conditions</Text>
							<View style={{ flexDirection: 'column' }}>
								{quote_addons
									.filter(
										(line) =>
											line.addon_condition != '' &&
											line.addon_condition !== null &&
											line.description != '' &&
											line.type != null
									)
									.map((line) => (
										<View style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											<Text style={{ fontWeight: 'bold' }}>
												Additional Item: {line.description}
											</Text>
											<Text>{line.addon_condition}</Text>
										</View>
									))}
							</View>
						</View>
					)}
					<Footer />
				</Page>
			)}
			{(quote_data.file_1 != '' ||
				quote_data.file_2 != '' ||
				quote_data.file_3 != '' ||
				quote_data.file_4 != '') && (
				<Page size="A4" style={styles.page_1}>
					{quote_data?.file_1 != null && quote_data?.file_1 != '' ? (
						<View key={'image_x_1'} style={styles.quadrant}>
							<Image src={quote_data?.file_1} style={styles.image} />
						</View>
					) : null}
					{quote_data?.file_2 != null && quote_data?.file_2 != '' ? (
						<View key={'image_x_2'} style={styles.quadrant}>
							<Image src={quote_data?.file_2} style={styles.image} />
						</View>
					) : null}
					{quote_data?.file_3 != null && quote_data?.file_3 != '' ? (
						<View key={'image_x_3'} style={styles.quadrant}>
							<Image src={quote_data?.file_3} style={styles.image} />
						</View>
					) : null}
					{quote_data?.file_4 != null && quote_data?.file_4 != '' ? (
						<View key={'image_x_4'} style={styles.quadrant}>
							<Image src={quote_data?.file_4} style={styles.image} />
						</View>
					) : null}
				</Page>
			)}
		</Document>
	)
}
