import { View } from '@react-pdf/renderer'
import { tableStyles as styles } from './styles'
import { ColumnTypes, columns } from './Columns'
import { Header } from './Header'
import { QuoteLabourLinesRows } from './QuoteLabourLinesRows'
import { AdditionalRow } from './AdditionalRow'
import { FooterQuoteLabourLines } from './QuoteLabourLineFooter'
import { AdditionalFooterRow } from './AdditionalFooterFooter'
import { QuoteHireLinesRows } from './QuoteHireLinesRows'
import { FooterQuoteHireLines } from './QuoteHireLineFooter'
import { QuoteTotalLineRow } from './QuoteTotalLineRow'
import { QuoteTotalFooterRow } from './QuoteTotalFooterRow'

interface TableProps {
	type: ColumnTypes
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any
}

export const Table = ({ type, data }: TableProps) => {
	const columnsarr = columns[type]
	console.log(type)
	console.log("Data: ", data)

	return (
		<View style={styles.tableContainer}>
			<Header columns={columnsarr} />

			{(type === ColumnTypes.quote_hire_lines) && <QuoteHireLinesRows items={data} />}
			{(type === ColumnTypes.quote_hire_lines) && <FooterQuoteHireLines items={data} />}

			{/* {(type === ColumnTypes.quote_total) && <QuoteTotalLineRow items={data} />} */}
			{(type === ColumnTypes.quote_total) && <QuoteTotalFooterRow items={data} />}

			{(type === ColumnTypes.quote_labour_lines) && <QuoteLabourLinesRows items={data} />}
			{(type === ColumnTypes.quote_labour_lines) && <FooterQuoteLabourLines items={data} />}

			{type === ColumnTypes.quote_addons && <AdditionalRow items={data} />}
			{type === ColumnTypes.quote_addons && (
				<AdditionalFooterRow items={data} />
			)}
		</View>
	)
}
